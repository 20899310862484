import React from "react";
import "../styles/global.scss";
import {Link} from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image"


const TeamMemberContainer = styled.div`
    margin-bottom: 50px;
   .blogheadline{
        color: #${props => props.color};
   }
   .blogbtn{
    background-color: #${props => props.color};
   }
   img{
    width: 100%;
  
   }
   text-align: center;
`

const TeamMember = ({name, job, email, text, img}) => {
    return (
        <TeamMemberContainer>

            <GatsbyImage image={img} alt={name} className="blogimg imageround"></GatsbyImage>

            <p className="small bold team__name">{name}</p>

            <p className="small">{job}</p>
            <br/>
            <p className="small description minheightteam">{text}</p>

            <br/><br/>
            <Link to={"mailto:" + email} className={"btn outline black medium smalltext bold jobtbn"}>E-MAIL</Link>

        </TeamMemberContainer>
    )
}
export default TeamMember
