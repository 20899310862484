import React, {useState} from "react";
import {graphql, Link} from "gatsby";

import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import styled from "styled-components";

import {GatsbyImage} from "gatsby-plugin-image";
import BlogEntry from "../components/BlogEntry";
import TeamMember from "../components/TeamMember";
import JobList from "../components/JobList";
import Head from "../components/Head";
import TestimonialSwiper from "../components/TestimonialSwiper";

export const query = graphql`
    {
    allSanityService {
    edges {
      node {
        image1 {
          asset {
            gatsbyImageData
          }
        }
        image2 {
          asset {
            gatsbyImageData
          }
        }
        image3 {
          asset {
            gatsbyImageData
          }
        }
       
        serviceoverviewheadline
        servicesheadline
        testimonial {
          text
          title
          ort
          image{asset{gatsbyImageData}}
        }
        referenzen {
          image {
            asset {
              gatsbyImageData
            }
          }
          title
        }
        service {
          image {
            asset {
              gatsbyImageData
            }
          }
          text
          title
          slug
        }
        service2 {
          text
          title
          slug
          image {
            asset {
              gatsbyImageData
            }
          }
          image2 {
            asset {
              gatsbyImageData
            }
          }
        }
        endimages {
          asset {
            gatsbyImageData
          }
        }
        servicessteps{
        title
        text
        image{asset{gatsbyImageData}}
       
    }
      }
    }
    
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }   
}
`

const Service = ({data}) => {

    const service = data.allSanityService.edges[0].node;

    function addLeadingZeros(num, totalLength) {
        return String(num).padStart(totalLength, '0');
    }

    return (
        <Layout settings={data.allSanitySettings.edges[0].node} service={true} landing={true}>
            <Head title="Services"/>
            <div className="serviceoverview__landing__container">
                <div className="serviceoverview__landing__row big">
                    <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee desktop" speed={120}>
                        <div className="marqueeText">
                            <span className="marqueeText__inner">GESAMTKONZEPTE —</span>
                            <span className="marqueeText__inner">GESAMTKONZEPTE —</span>
                            <span className="marqueeText__inner">GESAMTKONZEPTE —</span>
                            <span className="marqueeText__inner">GESAMTKONZEPTE —</span>
                            <span className="marqueeText__inner">GESAMTKONZEPTE —</span>
                            <span className="marqueeText__inner">GESAMTKONZEPTE —</span>
                        </div>
                    </Marquee>
                    <div className="mobileserviceheadline mobile">
                        <p className="large color-white">Gesamt&shy;konzepte</p>
                    </div>
                    <div className="serviceoverview__landing__image orange">
                        <GatsbyImage image={service.image1.asset.gatsbyImageData} alt="PRIVATE HAUSHALTE"></GatsbyImage>
                    </div>
                </div>
                <div className="serviceoverview__landing__row small">
                    <div className="marquee__wrapper">
                        <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee2 services abs" direction={"right"} speed={70}>
                            <div className="marqueeText">
                                <span className="marqueeText__inner orange">Phototvoltaik</span>
                                <span className="marqueeText__inner bluegrey">Batteriespeicher</span>
                                <span className="marqueeText__inner pink">Wärme</span>
                                <span className="marqueeText__inner green">E-Mobilität</span>
                            </div>
                        </Marquee>
                        <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee2 abs" direction={"right"} speed={150}>
                            <div className="marqueeText">
                                <span className="marqueeText__inner pink">Beratung</span>
                                <span className="marqueeText__inner green">Vertrieb</span>
                                <span className="marqueeText__inner pink">Planung</span>
                                <span className="marqueeText__inner bluegrey">Montage</span>
                            </div>
                        </Marquee>
                        <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee2" direction={"right"} speed={40}>
                            <div className="marqueeText">
                                <span className="marqueeText__inner orange">Ankauf</span>
                                <span className="marqueeText__inner orange">Verkauf</span>
                            </div>
                        </Marquee>
                    </div>
                </div>
                <div className="serviceoverview__landing__row big">
                    <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee desktop" speed={80}>
                        <div className="marqueeText">
                            <span className="marqueeText__inner">ANLAGENEINZELTEILE —</span>
                            <span className="marqueeText__inner">ANLAGENEINZELTEILE —</span>
                            <span className="marqueeText__inner">ANLAGENEINZELTEILE —</span>
                            <span className="marqueeText__inner">ANLAGENEINZELTEILE —</span>
                        </div>
                    </Marquee>
                    <div className="mobileserviceheadline mobile">
                        <p className="large color-white">Anlagen&shy;einzelteile</p>
                    </div>
                    <div className="serviceoverview__landing__image green">
                        <GatsbyImage image={service.image2.asset.gatsbyImageData} alt="GEWERBE"></GatsbyImage>
                    </div>
                </div>
                <div className="serviceoverview__landing__row small">
                    <div className="marquee__wrapper">
                        <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee2 services abs" direction={"right"} speed={70}>
                            <div className="marqueeText">
                                <span className="marqueeText__inner orange">Phototvoltaik</span>
                                <span className="marqueeText__inner bluegrey">Batteriespeicher</span>
                                <span className="marqueeText__inner pink">Wärme</span>
                                <span className="marqueeText__inner green">E-Mobilität</span>
                            </div>
                        </Marquee>
                        <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee2 abs" direction={"right"} speed={150} delay={-2}>
                            <div className="marqueeText">
                                <span className="marqueeText__inner pink">Beratung</span>
                                <span className="marqueeText__inner green">Vertrieb</span>
                                <span className="marqueeText__inner pink">Planung</span>
                                <span className="marqueeText__inner bluegrey">Montage</span>
                            </div>
                        </Marquee>
                        <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee2" direction={"right"} speed={40} delay={-3}>
                            <div className="marqueeText">
                                <span className="marqueeText__inner orange">Ankauf</span>
                                <span className="marqueeText__inner orange">Verkauf</span>
                            </div>
                        </Marquee>
                    </div>
                </div>
                <div className="serviceoverview__landing__row big">
                    <Marquee gradient={false} className="marqueeWrapper serviceoverview__landing__marquee desktop" speed={100}>
                        <div className="marqueeText">
                            <span className="marqueeText__inner">WARTUNG & SERVICE —</span>
                            <span className="marqueeText__inner">WARTUNG & SERVICE —</span>
                            <span className="marqueeText__inner">WARTUNG & SERVICE —</span>
                            <span className="marqueeText__inner">WARTUNG & SERVICE —</span>
                        </div>
                    </Marquee>
                    <div className="mobileserviceheadline mobile">
                        <p className="large color-white">Wartung & Service</p>
                    </div>
                    <div className="serviceoverview__landing__image pink">
                        <GatsbyImage image={service.image3.asset.gatsbyImageData} alt="INDUSTRIELLE LÖSUNGEN"></GatsbyImage>
                    </div>
                </div>
            </div>

            <div className="servicelinks__container">
                <h2 className="color-orange uppercase">
                    {service.servicesheadline}
                </h2>
                <div className="serviceroad__svg__container">
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">01</p>
                            <div className="coloredring__container first">
                                <div className="colorringimage pink">
                                    <GatsbyImage className="imageround" image={service.servicessteps[0].image.asset.gatsbyImageData} alt={service.servicessteps[0].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after pink"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[0].title}</p>
                            <p className="small">{service.servicessteps[0].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">02</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before pink"></div>
                                <div className="colorringimage pink">
                                    <GatsbyImage className="imageround" image={service.servicessteps[1].image.asset.gatsbyImageData} alt={service.servicessteps[1].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after pink"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[1].title}</p>
                            <p className="small">{service.servicessteps[1].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">03</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before pink"></div>
                                <div className="colorringimage pink">
                                    <GatsbyImage className="imageround" image={service.servicessteps[2].image.asset.gatsbyImageData} alt={service.servicessteps[2].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after pink"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[2].title}</p>
                            <p className="small">{service.servicessteps[2].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">04</p>
                            <div className="coloredring__container last">
                                <div className="road__verbindung-before pink"></div>
                                <div className="colorringimage pink">
                                    <GatsbyImage className="imageround" image={service.servicessteps[3].image.asset.gatsbyImageData} alt={service.servicessteps[3].title}></GatsbyImage>
                                </div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[3].title}</p>
                            <p className="small">{service.servicessteps[3].text}</p>
                        </div>
                    </div>
                    <div className="road__newrow__container">
                        <div className="road__newrow pink">
                            <div className="rightcorner"></div>
                            <div className="middle"></div>
                            <div className="leftcorner"></div>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">05</p>
                            <div className="coloredring__container first">
                                <div className="colorringimage pink">
                                    <GatsbyImage className="imageround" image={service.servicessteps[4].image.asset.gatsbyImageData} alt={service.servicessteps[4].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after pink"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[4].title}</p>
                            <p className="small">{service.servicessteps[4].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">06</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before pink"></div>
                                <div className="colorringimage pink">
                                    <GatsbyImage className="imageround" image={service.servicessteps[5].image.asset.gatsbyImageData} alt={service.servicessteps[5].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after pink"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[5].title}</p>
                            <p className="small">{service.servicessteps[5].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">07</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before pink"></div>
                                <div className="colorringimage pink">
                                    <GatsbyImage className="imageround" image={service.servicessteps[6].image.asset.gatsbyImageData} alt={service.servicessteps[6].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after green"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[6].title}</p>
                            <p className="small">{service.servicessteps[6].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">08</p>
                            <div className="coloredring__container last">
                                <div className="road__verbindung-before green"></div>
                                <div className="colorringimage green">
                                    <GatsbyImage className="imageround" image={service.servicessteps[7].image.asset.gatsbyImageData} alt={service.servicessteps[7].title}></GatsbyImage>
                                </div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[7].title}</p>
                            <p className="small">{service.servicessteps[7].text}</p>
                        </div>
                    </div>
                    <div className="road__newrow__container">
                        <div className="road__newrow green">
                            <div className="rightcorner"></div>
                            <div className="middle"></div>
                            <div className="leftcorner"></div>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">09</p>
                            <div className="coloredring__container first">
                                <div className="colorringimage green">
                                    <GatsbyImage className="imageround" image={service.servicessteps[8].image.asset.gatsbyImageData} alt={service.servicessteps[8].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after green"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[8].title}</p>
                            <p className="small">{service.servicessteps[8].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">10</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before green"></div>
                                <div className="colorringimage green">
                                    <GatsbyImage className="imageround" image={service.servicessteps[9].image.asset.gatsbyImageData} alt={service.servicessteps[9].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after green"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[9].title}</p>
                            <p className="small">{service.servicessteps[9].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">11</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before green"></div>
                                <div className="colorringimage green">
                                    <GatsbyImage className="imageround" image={service.servicessteps[10].image.asset.gatsbyImageData} alt={service.servicessteps[10].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after green"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[10].title}</p>
                            <p className="small">{service.servicessteps[10].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">12</p>
                            <div className="coloredring__container last">
                                <div className="road__verbindung-before green"></div>
                                <div className="colorringimage green">
                                    <GatsbyImage className="imageround" image={service.servicessteps[11].image.asset.gatsbyImageData} alt={service.servicessteps[11].title}></GatsbyImage>
                                </div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[11].title}</p>
                            <p className="small">{service.servicessteps[11].text}</p>
                        </div>
                    </div>
                    <div className="road__newrow__container">
                        <div className="road__newrow green">
                            <div className="rightcorner"></div>
                            <div className="middle"></div>
                            <div className="leftcorner"></div>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">13</p>
                            <div className="coloredring__container first">
                                <div className="colorringimage bluegrey">
                                    <GatsbyImage className="imageround" image={service.servicessteps[12].image.asset.gatsbyImageData} alt={service.servicessteps[12].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after bluegrey"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[12].title}</p>
                            <p className="small">{service.servicessteps[12].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">14</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before bluegrey"></div>
                                <div className="colorringimage bluegrey">
                                    <GatsbyImage className="imageround" image={service.servicessteps[13].image.asset.gatsbyImageData} alt={service.servicessteps[13].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after bluegrey"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[13].title}</p>
                            <p className="small">{service.servicessteps[13].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">15</p>
                            <div className="coloredring__container">
                                <div className="road__verbindung-before bluegrey"></div>
                                <div className="colorringimage bluegrey">
                                    <GatsbyImage className="imageround" image={service.servicessteps[14].image.asset.gatsbyImageData} alt={service.servicessteps[14].title}></GatsbyImage>
                                </div>
                                <div className="road__verbindung-after bluegrey"></div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[14].title}</p>
                            <p className="small">{service.servicessteps[14].text}</p>
                        </div>
                    </div>
                    <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">16</p>
                            <div className="coloredring__container last">
                                <div className="road__verbindung-before bluegrey"></div>
                                <div className="colorringimage bluegrey">
                                    <GatsbyImage className="imageround" image={service.servicessteps[15].image.asset.gatsbyImageData} alt={service.servicessteps[15].title}></GatsbyImage>
                                </div>
                            </div>
                            <p className="small bold uppercase">{service.servicessteps[15].title}</p>
                            <p className="small">{service.servicessteps[15].text}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="servicelinks__container">
                <h2 className="color-orange uppercase">
                    {service.serviceoverviewheadline}
                </h2>
                <div className="servicelinks__wrapper">
                    {service.service.map(el => (
                        <div className="service__einzel__container">
                            <GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.title}></GatsbyImage>
                            <p className="large">{el.title}</p>
                            <p className="small">{el.text}</p>
                            <Link to={el.slug} className="btn filled orange bold smalltext small hover-moveup">MEHR
                                INFO</Link>
                        </div>
                        ))}
                </div>
                <div className="servicelinks__wrapper feature">
                    <div className="service__feature__container">
                        <div className="service__feature__imgs">
                            <GatsbyImage image={service.service2.image.asset.gatsbyImageData} alt={service.service2.title}></GatsbyImage>
                            <GatsbyImage image={service.service2.image2.asset.gatsbyImageData} alt={service.service2.title} className="desktop"></GatsbyImage>

                        </div>
                        <p className="large">{service.service2.title}</p>
                        <p className="small">{service.service2.text}</p>
                        <Link to={service.service2.slug} className="btn filled orange bold smalltext small hover-moveup">JETZT KONTAKTIEREN</Link>
                    </div>
                </div>
            </div>
            <TestimonialSwiper testimonials={service.testimonial}></TestimonialSwiper>
            <div className="reference__container">
                <Marquee gradient={false} className="marqueeWrapper color-orange">
                    <div className="marqueeText">
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                        <span className="marqueeText__inner ">REFERENZEN</span>
                    </div>
                </Marquee>
                <div className="reference__wrapper ">
                    {service.referenzen.map(el => (
                        <div className="referenzen__einzel__container">
                            <GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.title}></GatsbyImage>
                            <p className="large mobile-small mobile-bold">{el.title}</p>
                        </div>
                    ))}
                </div>
                <div className="referenze__images__container">
                    <Marquee gradient={false} className="bgbluegrey marqueeWrapper imagemarquee">
                        <div className="marqueeText">
                            {service.endimages.map(el => (
                                <span className="marqueeText__inner"><GatsbyImage image={el.asset.gatsbyImageData} alt="REFERENZEN"></GatsbyImage></span>
                            ))}
                            {service.endimages.map(el => (
                                <span className="marqueeText__inner"><GatsbyImage image={el.asset.gatsbyImageData} alt="REFERENZEN"></GatsbyImage></span>
                            ))}
                        </div>
                    </Marquee>
                </div>
            </div>
            <div className="service__testimonial__container color-whiteonblack">
                <div className="service__testi__left">
                    <p className="large nomargin">Benötigen Sie eine individuelle Beratung? <br/>Wir sind gerne jederzeit für Sie da!</p>
                </div>
                <div className="service__testi__right arrowbtn__wrapper">
                    <Link to={"/service"} className="btn arrowbtn bluegrey smalltext bold filled">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                            <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                    <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                    <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                </g>
                                <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                    <g id="Group_13" data-name="Group 13">
                                        <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <p className="small bold largesection">PHOTOVOLTAIK PLANEN</p>
                    </Link>
                    <Link to={"/#energierechner"} className="btn arrowbtn green smalltext bold filled">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                            <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                    <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                    <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                </g>
                                <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                    <g id="Group_13" data-name="Group 13">
                                        <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <p className="small bold largesection">PERS. STROMPREISE BERECHNEN</p>
                    </Link>
                    <Link to={"/kontakt"} className="btn arrowbtn pink smalltext bold filled">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                            <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                    <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                    <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                </g>
                                <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                    <g id="Group_13" data-name="Group 13">
                                        <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <p className="small bold largesection">TERMIN VEREINBAREN</p>
                    </Link>
                    <Link to={"/blog"} className="btn arrowbtn orange smalltext bold filled">
                        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                            <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                    <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                    <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                </g>
                                <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                    <g id="Group_13" data-name="Group 13">
                                        <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <p className="small bold largesection">GRÜNER BLOG / GRÜNES LEBEN</p>
                    </Link>
                </div>
            </div>

        </Layout>
    )
}

export default Service;


/*
{service.servicessteps.map((el, index) => (
                        <div className="road__element__wrapper">
                        <div className="road__element">
                            <p className="large">{addLeadingZeros(index+1, 2)}</p>
                            <ColorRingImage className="colorringimage" color={el.color}>
                                <GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.title}></GatsbyImage>
                            </ColorRingImage>
                            <p className="small bold uppercase">{el.title}</p>
                            <p className="small">{el.text}</p>
                        </div>
                        </div>
                    ))}
 */
